var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 17",
        fill: _vm.fill,
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M4.21442 1H20.2859C20.8542 1 21.3992 1.22576 21.8011 1.62763C22.2029 2.02949 22.4287 2.57454 22.4287 3.14286V13.8571C22.4287 14.4255 22.2029 14.9705 21.8011 15.3724C21.3992 15.7742 20.8542 16 20.2859 16H3.14299C2.57467 16 2.02963 15.7742 1.62777 15.3724C1.2259 14.9705 1.00014 14.4255 1.00014 13.8571V3.14286C1.00014 2.57454 1.2259 2.02949 1.62777 1.62763C2.02963 1.22576 2.57467 1 3.14299 1H4.21442Z",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.8874 9.28964L11.8272 5.22949L7.76709 9.28964",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.8274 5.22949V11.9964",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "draggable-modal",
      style: _vm.style,
      attrs: { id: "draggable-modal" },
    },
    [
      _c(
        "div",
        {
          ref: "draggableModalHeader",
          staticClass: "draggable-modal-header",
          attrs: { id: "draggable-modal-header" },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="loader-icon">
    <div class="loader-icon-loader" :style="loaderStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'LoaderIcon',
  props: {
    color: {
      type: String,
      default: '#fff',
      required: false
    },
    width: {
      type: Number,
      default: 35,
      required: false
    },
    height: {
      type: Number,
      default: 35,
      required: false
    }
  },
  computed: {
    borderWidth() {
      const width = Math.ceil((this.height * 12) / 100)
      return width
    },
    loaderStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
        border: `${this.borderWidth}px solid #f3f3f3`,
        borderTop: `${this.borderWidth}px solid #12598d`
      }
    }
  }
}
</script>
<style lang="scss">
.loader-icon {
  &-loader {
    animation: spin 1.5s linear infinite;
    border-radius: 50%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
